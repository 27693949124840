import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Metatags from "../components/metatags"
import Navbar from "../components/navbar"
import Footer from "../components/footer"

const WhoWeAre = ({ data }) => (
  <>
    <Metatags
      title="daucodesigns | proycetos"
      description="detrás de cada producto, hay una historia"
      url="https://daucodesigns.com"
      image="https://daucodesigns.com/logo-daucodesigns.png"
      keywords="muebles, design"
    />
    <Navbar />
    <div className="height40" />
    <div className="height40" />
    <Container className="big-container">
      <Row>
        <Col>
          <div className="text-center projects">
            <h4 className="marcas-title nuestras-marcas-title">proyectos</h4>
            <h5>
              estes son los proyectos exclusivos diseñados
              <br />
              por el equipo de daucodesigns
            </h5>
          </div>
        </Col>
      </Row>
    </Container>
    <div className="height40" />
    <Container className="big-container">
      <Row>
        <Col md={6} className="projects-col">
          <Link to="/proyectos/hotel-kimpton-vividora">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.projects1)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="hotel kimpton vividora"
                  />
                </div>
              </div>
              <div className="text-center project-button-wrapper">
                <Button className="project-button">
                  <span className="project-button-title">
                    hotel kimpton vividora
                  </span>
                  <br />
                  <span className="project-button-city">barcelona</span>
                </Button>
              </div>
            </div>
          </Link>
        </Col>
        <Col md={6} className="projects-col">
          <Link to="/proyectos/iese">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.projects2)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="iese"
                  />
                </div>
              </div>
              <div className="text-center project-button-wrapper">
                <Button className="project-button">
                  <span className="project-button-title">iese</span>
                  <br />
                  <span className="project-button-city">barcelona</span>
                </Button>
              </div>
            </div>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="projects-col">
          <Link to="/proyectos/augusta-abogados">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.projects3)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="augusta abogados"
                  />
                </div>
              </div>
              <div className="text-center project-button-wrapper">
                <Button className="project-button">
                  <span className="project-button-title">augusta abogados</span>
                  <br />
                  <span className="project-button-city">barcelona</span>
                </Button>
              </div>
            </div>
          </Link>
        </Col>
        <Col md={6} className="projects-col">
          <Link to="/proyectos/oficina-swarowski">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.projects4)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="augusta abogados"
                  />
                </div>
              </div>
              <div className="text-center project-button-wrapper">
                <Button className="project-button">
                  <span className="project-button-title">
                    oficina swarovski
                  </span>
                  <br />
                  <span className="project-button-city">barcelona</span>
                </Button>
              </div>
            </div>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="projects-col">
          <Link to="/proyectos/hotel-boutique-sitges">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.projects5)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="augusta abogados"
                  />
                </div>
              </div>
              <div className="text-center project-button-wrapper">
                <Button className="project-button">
                  <span className="project-button-title">
                    hotel boutique
                  </span>
                  <br />
                  <span className="project-button-city">sitges</span>
                </Button>
              </div>
            </div>
          </Link>
        </Col>
        <Col md={6} className="projects-col">
          <Link to="/proyectos/apartamento-palla">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.projects6)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="augusta abogados"
                  />
                </div>
              </div>
              <div className="text-center project-button-wrapper">
                <Button className="project-button">
                  <span className="project-button-title">
                    apartamento palla
                  </span>
                  <br />
                  <span className="project-button-city">barcelona</span>
                </Button>
              </div>
            </div>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="projects-col">
          <Link to="/proyectos/youplanet">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.projects7)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="augusta abogados"
                  />
                </div>
              </div>
              <div className="text-center project-button-wrapper">
                <Button className="project-button">
                  <span className="project-button-title">youplanet</span>
                  <br />
                  <span className="project-button-city">barcelona</span>
                </Button>
              </div>
            </div>
          </Link>
        </Col>
        <Col md={6} className="projects-col">
          <Link to="/proyectos/hotel-arima">
            <div className="brand-wrapper">
              <div className="brand-image-wrapper">
                <div className="img-hover-zoom">
                  <GatsbyImage
                    image={getImage(data.projects8)}
                    backgroundColor={false}
                    className="img-responsive"
                    alt="augusta abogados"
                  />
                </div>
              </div>
              <div className="text-center project-button-wrapper">
                <Button className="project-button">
                  <span className="project-button-title">hotel arima</span>
                  <br />
                  <span className="project-button-city">san sebastian</span>
                </Button>
              </div>
            </div>
          </Link>
        </Col>
      </Row>
    </Container>
    <div className="height40" />
    <div className="height40" />
    <div className="height40" />
    <Footer />
  </>
)

export default WhoWeAre

export const query = graphql`
  query pageProjects {
    projects1: file(
      relativePath: { eq: "projects/proyectos_dauco_principal_1.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    projects2: file(
      relativePath: { eq: "projects/proyectos_dauco_principal_5.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    projects3: file(
      relativePath: { eq: "projects/proyectos_dauco_principal_3.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    projects4: file(
      relativePath: { eq: "projects/proyectos_dauco_principal_4.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    projects5: file(
      relativePath: { eq: "projects/proyectos_dauco_principal_6.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    projects6: file(
      relativePath: { eq: "projects/proyectos_dauco_principal_7.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    projects7: file(
      relativePath: { eq: "projects/proyectos_dauco_principal_8.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
    projects8: file(
      relativePath: { eq: "projects/proyectos_dauco_principal_9.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED)
      }
    }
  }
`
